export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your order!"])},
        "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue shopping"])}
      },
      "de": {
        "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Bestellung!"])},
        "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter einkaufen"])}
      }
    }
  })
}