export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
        "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])}
      },
      "de": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
        "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinigte Staaten"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])}
      }
    }
  })
}