export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "streetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number and street name"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment, suite, unit etc. (optional)"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode / ZIP"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])}
      },
      "de": {
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "streetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung, Suite, Einheit etc. (optional)"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land auswählen"])}
      }
    }
  })
}