export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "midSeasonSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MID-SEASON SALE"])},
        "up50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP TO 50%"])},
        "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOP NOW"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHECK OUT"])},
        "newCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW COLLECTION"])}
      },
      "de": {
        "midSeasonSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOMMERSCHLUSSVERKAUF"])},
        "up50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIS ZU 50%"])},
        "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JETZT SHOPPEN"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
        "newCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEUE KOLLEKTION"])}
      }
    }
  })
}