export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer 2015 – What inspires you?"])}
      },
      "de": {
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
        "teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommer 2015 – Was ist Ihre Inspiration?"])}
      }
    }
  })
}