export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "productDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdetails"])},
        "deliveryReturns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand & Retoure"])},
        "freeReturns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Retoure."])},
        "moreDeliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandinformationen"])}
      },
      "en": {
        "productDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product details"])},
        "deliveryReturns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery & Returns"])},
        "freeReturns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free return for all orders."])},
        "moreDeliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery information"])}
      }
    }
  })
}