export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
      },
      "de": {
        "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])}
      }
    }
  })
}