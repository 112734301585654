export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
        "quickView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick view"])},
        "moreColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More colours"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item to wishlist"])},
        "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Bag"])}
      },
      "de": {
        "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
        "quickView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellansicht"])},
        "moreColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Farben"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf den Wunschzettel"])},
        "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Warenkorb Hinzufügen"])}
      }
    }
  })
}