export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
        "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
      },
      "de": {
        "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
        "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])}
      }
    }
  })
}