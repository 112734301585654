export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
        "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger"])},
        "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])}
      },
      "en": {
        "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
        "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
        "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])}
      }
    }
  })
}