export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "w_jeans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOMEN JEANS"])},
        "sneakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNEAKERS"])},
        "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESSORIES"])},
        "bathing_suits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BATHING SUITS"])},
        "free_shipping_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Worldwide Shipping"])},
        "free_shipping_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On all orders over"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
        "return_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Days Money Returns"])},
        "return_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 days money back guarantee"])},
        "support_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support 24/7"])},
        "support_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered in the country of usage"])},
        "secure_checkout_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Secure Checkout"])},
        "secure_checkout_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal / Visa / Master Card"])}
      },
      "de": {
        "w_jeans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAMEN JEANS"])},
        "sneakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNEAKERS"])},
        "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUBEHÖR"])},
        "bathing_suits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BADESUITENS"])},
        "free_shipping_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser weltweiter Versand"])},
        "free_shipping_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für alle Bestellungen über"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
        "return_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Tage Geldrückgabe"])},
        "return_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Tage Geld-zurück-Garantie"])},
        "support_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützung 24/7"])},
        "support_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angeboten im Land der Nutzung"])},
        "secure_checkout_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% sicherer Checkout"])},
        "secure_checkout_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal / Visa / Master Card"])}
      }
    }
  })
}