export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "miniCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])},
        "viewBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])},
        "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist derzeit leer"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])}
      },
      "en": {
        "miniCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Cart"])},
        "viewBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Cart"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
        "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is currently empty"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])}
      }
    }
  })
}