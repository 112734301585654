export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "returnStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "de": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "returnStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      }
    }
  })
}