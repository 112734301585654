export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you like to return?"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one item to return"])},
        "modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your return was successfull"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was möchten Sie zurückschicken?"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückschicken"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie mindestens einen Artikel aus, der zurückgegeben werden soll"])},
        "modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Rückgabe war erfolgreich"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
      }
    }
  })
}