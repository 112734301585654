export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
        "addressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Book"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
        "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
        "returnsExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns / Exchange"])},
        "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wishlist"])},
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
        "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
      },
      "de": {
        "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten"])},
        "addressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressbuch"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Zahlungdaten"])},
        "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
        "returnsExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Retouren"])},
        "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wunschliste"])},
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
        "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
      }
    }
  })
}