export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order details"])},
        "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
        "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])},
        "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
        "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
        "appliedDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied discounts"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order not found"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return an item"])},
        "orderItemsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Items"])},
        "returnedItemsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned Items"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bestelldetails"])},
        "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnummer"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldatum"])},
        "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanschrift"])},
        "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
        "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandart"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
        "appliedDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angewandte Rabatte"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung nicht gefunden"])},
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel zurückschicken"])},
        "orderItemsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel bestellen"])},
        "returnedItemsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgegebene Artikel"])}
      }
    }
  })
}