export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "differentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship to a different address?"])},
        "billingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Details"])},
        "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order notes (optional)"])},
        "notesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes about your order, e.g. special notes for delivery."])}
      },
      "de": {
        "differentAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu einer anderen Adresse verschicken?"])},
        "billingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdetails"])},
        "orderNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellhinweise (optional)"])},
        "notesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweise zu Ihrer Bestellung, z. Besondere Hinweise zur Lieferung."])}
      }
    }
  })
}