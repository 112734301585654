export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
        "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
        "comm_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "facebook_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook group"])},
        "forums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forums"])},
        "meetups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meetups"])},
        "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGAL"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "use_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
        "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Agreement"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFILE"])},
        "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
        "order_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order tracking"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & Support"])},
        "join_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOIN OUR NEWSLETTER"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address..."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      },
      "de": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNTERNEHMEN"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
        "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEMEINSCHAFT"])},
        "comm_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaft"])},
        "facebook_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Gruppe"])},
        "forums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foren"])},
        "meetups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meetups"])},
        "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGAL"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])},
        "use_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
        "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzvereinbarung"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFIL"])},
        "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])},
        "order_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfolgung der Bestellung"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe & Unterstützung"])},
        "join_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNSEREN NEWSLETTER ABONNIEREN"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail eingeben ..."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])}
      }
    }
  })
}