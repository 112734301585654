export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "yourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cart"])},
        "itemsTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " item in total"]), _normalize([_interpolate(_named("n")), " items in total"])])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is currently empty"])},
        "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Shopping"])},
        "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop now"])},
        "startCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Checkout"])}
      },
      "de": {
        "yourCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb"])},
        "itemsTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Artikel im Warenkorb"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist derzeit leer"])},
        "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter einkaufen"])},
        "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt einkaufen"])},
        "startCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])}
      }
    }
  })
}