export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "invalidSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The token is invalid"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
      },
      "de": {
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Zurücksetzen"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Password"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "invalidSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Token ist ungültig"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])}
      }
    }
  })
}