export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "newPasswordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
        "newPasswordConfirmError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
        "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid current password"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
        "newPasswordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
        "newPasswordConfirmError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
        "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges aktuelles Passwort"])}
      }
    }
  })
}