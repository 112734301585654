export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "shipmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Status"])},
        "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
        "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
        "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "Delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delayed"])},
        "Partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial"])},
        "Backorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backorder"])},
        "BalanceDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Due"])},
        "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "CreditOwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Owed"])},
        "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
        "emptyOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not placed any orders yet!"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])}
      },
      "de": {
        "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best.-Nr."])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsstatus"])},
        "shipmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandstatus"])},
        "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandt"])},
        "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
        "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehend"])},
        "Delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verspätet"])},
        "Partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise"])},
        "Backorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferrückstand"])},
        "BalanceDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsbetrag"])},
        "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlgeschlagen"])},
        "CreditOwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditforderung"])},
        "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
        "emptyOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Bestellungen aufgegeben!"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])}
      }
    }
  })
}