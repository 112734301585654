export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
        "PayPal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal"])}
      },
      "de": {
        "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkarte"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsweise"])},
        "PayPal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal"])}
      }
    }
  })
}