export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "unknownValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid field"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid email is required"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It should contain at least ", _interpolate(_named("min")), " characters"])},
        "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are not the same"])},
        "mustBeAgreed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to the terms"])}
      },
      "de": {
        "unknownValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Feldwert"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine gültige E-Mail ist erforderlich"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es sollte mindestens ", _interpolate(_named("min")), " Zeichen enthalten"])},
        "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter sind nicht gleich"])},
        "mustBeAgreed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stimmen Sie den Bedingungen zu"])}
      }
    }
  })
}