export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
        "notYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it's not you"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From your account dashboard you can easily check & view your recent orders, manage your shipping and billing addresses and edit your password and account details."])}
      },
      "de": {
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo"])},
        "notYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das nicht Sie sind"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Ihr Konto-Dashboard können Sie ganz einfach Ihre letzten Bestellungen überprüfen und anzeigen. Verwalten Sie Ihre Versand- und Rechnungsadressen und bearbeiten Sie Ihr Passwort und Ihre Kontodaten."])}
      }
    }
  })
}