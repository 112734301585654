export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])}
      },
      "en": {
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])}
      }
    }
  })
}