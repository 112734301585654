export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "invalidSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer with this email was not found"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
      },
      "de": {
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "invalidSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde mit dieser E-Mail wurde nicht gefunden"])},
        "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])}
      }
    }
  })
}