export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "go-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to top"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Results Found"])},
        "categoryNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Not Found"])}
      },
      "de": {
        "go-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenanfang\""])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse Gefunden"])},
        "categoryNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie nicht gefunden"])}
      }
    }
  })
}