export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "badRequestError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There seems to be an issue with the request"])},
        "networkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a error in the connection, please try again later"])},
        "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
      },
      "de": {
        "badRequestError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt ein Problem mit der Anfrage"])},
        "networkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verbindung ist ein Fehler aufgetreten, versuchen Sie es bitte später nochmals"])},
        "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])}
      }
    }
  })
}