export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
        "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten"])},
        "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adresse"])}
      }
    }
  })
}