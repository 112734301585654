export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returning Customer Sign In"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Fields"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have an account, please sign in for a faster checkout"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
        "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenanmeldung"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen schnelleren Checkout Prozess, melden Sie sich bitte mit Ihrem persönlichen Kundenkonto ein."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet bleiben"])},
        "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
        "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
        "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anmeldeinformationen"])}
      }
    }
  })
}