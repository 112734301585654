export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Warenkorb Hinzufügen"])},
        "addToShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["??????"])},
        "notInStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel ist nicht auf Lager"])},
        "quantityError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geben Sie den Wert kleiner oder gleich ", _interpolate(_named("quantity")), " ein"])}
      },
      "en": {
        "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Shopping Bag"])},
        "addToShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Shopping List"])},
        "notInStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item is not in stock"])},
        "quantityError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter the value less or equal to ", _interpolate(_named("quantity"))])}
      }
    }
  })
}