export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cartTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart totals"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
        "salesTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Tax"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])}
      },
      "de": {
        "cartTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorbsummen"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
        "salesTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt."])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatt"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])}
      }
    }
  })
}