export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to store your products, easy checkouts, customer discounts, benefits and more."])},
        "titleSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "secondName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
        "repeatPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
        "pleaseAddMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add me to the"])},
        "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUNRISE Newsletter"])},
        "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Terms and Conditions"])},
        "agreeToTermsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the terms"])},
        "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
        "duplicatedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A customer with this email already exists"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resigstrierung"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto einrichten, um das Shoppen noch einfacher zu machen."])},
        "titleSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "secondName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bestätigen"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
        "repeatPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
        "pleaseAddMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung zum"])},
        "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUNRISE Newsletter"])},
        "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme den AGB zu"])},
        "agreeToTermsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen den Bedingungen zustimmen"])},
        "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt registieren"])},
        "duplicatedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kunde mit dieser E-Mail existiert bereits"])}
      }
    }
  })
}