export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])}
      },
      "de": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
        "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])}
      }
    }
  })
}