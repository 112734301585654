export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "itemsTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " item in total"]), _normalize([_interpolate(_named("n")), " items in total"])])},
        "editMyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my shopping cart"])},
        "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
        "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])}
      },
      "de": {
        "itemsTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Artikel im Warenkorb"])},
        "editMyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb bearbeiten"])},
        "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellübersicht"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vesrsand"])},
        "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])}
      }
    }
  })
}